import { Tag as AntTag, Collapse, Typography } from 'antd';
import styled, { keyframes } from 'styled-components';

import { Box } from '@/components';

export const Tag = styled(AntTag)`
  top: var(--ss);
  left: var(--ss);
  background-color: #22b502;
  border-radius: 6px;
`;

export const ImageCount = styled(Box).attrs(() => ({
  $bg: '#10131b80',
  $rounded: '4px',
  $p: '2px 6px',
  $direction: 'row',
  $align: 'center',
  $gap: '4px',
}))`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const Capitalize = styled(Typography.Text)`
  text-transform: capitalize;
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: var(--xs) !important;
  }

  .ant-collapse-content-box {
    padding: var(--xs) !important;
    padding-top: 0 !important;
  }
`;

export const Sticky = styled(Box).attrs(() => ({
  $justify: 'space-between',
  $bg: '#CADFF9',
  $p: '4px 16px',
}))`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const progressLinearAnimation = keyframes`
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
`;

export const ProgressLinear = styled.progress`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 0.2em;
  width: var(--full);
  color: var(--primary);
  background-color: rgba(14, 165, 233, 0.12);
  font-size: var(--base);
  position: absolute;
  bottom: -10px;
  left: 0;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
  }

  &:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      transparent 50%,
      currentColor 50%,
      currentColor 60%,
      transparent 60%,
      transparent 71.5%,
      currentColor 71.5%,
      currentColor 84%,
      transparent 84%
    );
    animation: ${progressLinearAnimation} 2s infinite linear;
  }

  &:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }

  &:indeterminate::-ms-fill {
    animation-name: none;
  }
`;
