export const ROUTES = {
  home: '/main',
  all: '*',
  single: ':id',
  details: 'details',
  category: 'category',
  payment: 'payment',
  paymentSuccess: '/payment-success',
  paymentSuccessV2: '/payment-success-v2',
  termsConditions: '/terms-n-conditions',
  result: 'result',
  search: 'search',
  rooms: 'rooms',
  booking: 'booking',
  notifications: 'notifications',
  reviews: '/reviews',
  passengers: 'passengers',
  faq: 'faq',
  travelDate: 'travel-date',

  // PROFILE
  profile: '/profile',
  balance: 'balance',
  delete: 'delete',
  info: 'info',
  language: 'language',
  history: 'history',
  orders: 'orders',
  help: 'help',
  privacy: 'privacy-policy',

  // SERVICES
  services: '/services',
  tickets: 'tickets',
  tours: 'tours',
  transfer: 'transfer',
  hotels: 'hotels',

  // AUTH
  sign: '/sign',
  verify: '/verify',
  welcome: '/welcome',
} as const;
