import { lazy, Suspense } from 'react';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { initializeFaro } from '@grafana/faro-react';

import { useLang } from './util';
import {
  HistoryRouterProvider,
  QueryProvider,
  ThemeProvider,
} from './providers';

import { history } from '@/utils/history';
import { Loading } from '@/components';
import ErrorBoundary from '@/components/error-boundary';

if (import.meta.env.PROD) {
  initializeFaro({
    url: 'https://alloy.coreteam.uz/collect',

    app: {
      name: 'app-front',
    },
  });
}

dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const Router = lazy(() => import('@/router'));

export const App = () => {
  useLang();

  return (
    <ThemeProvider>
      <QueryProvider>
        <ErrorBoundary>
          <HistoryRouterProvider history={history}>
            <Suspense fallback={<Loading isFull />}>
              <Router />
            </Suspense>
          </HistoryRouterProvider>
        </ErrorBoundary>
      </QueryProvider>
    </ThemeProvider>
  );
};
